import { useAuthState } from "logic/auth/auth-state";
import MaintenanceTemplate from "pages/maintenance";
import { useMemo } from "react";
import { MainContent } from "./components/main-content";
import { SignInMobile } from "./components/sign-in-container";
import { TopBarMobile } from "./components/top-bar-mobile";

export function IndexContainerMobile(props: { isMaintenance: boolean }) {
  const auth = useAuthState();

  const isAnonymous = useMemo(
    () => auth.session.data?.user.isAnonymous,
    [auth.session.data?.user.isAnonymous]
  );

  function Content() {
    return (
      <>
        <TopBarMobile />
        <div>{!isAnonymous ? <MainContent /> : <SignInMobile />}</div>
      </>
    );
  }

  return props.isMaintenance ? <MaintenanceTemplate /> : <Content />;
}
