import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { faPowerOff, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Drawer, Flex, Image, Menu, MenuProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useToken } from "components/ui-kit/core/token";
import { signOut } from "firebase/auth";
import { getAnalytics } from "logic/analytics/analytics";
import { useAuthState } from "logic/auth/auth-state";
import { getFirebaseAuth } from "logic/internals/apis/firebase/firebase-auth";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import router from "next/router";
import { useCallback, useMemo, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { INDEX_ROUTE } from "templates/index/index-routes";
import mobilelogo from "./assets/mobile-logo.svg";

enum MenuKeys {
  HISTORY = "History",
  FEEDBACK = "Feedback",
  LOGIN = "Sign up / Login",
  GENERATE_STUDY = "generate-study",
  SIGN_OUT = "sign-out",
}

export function TopBarMobile() {
  const token = useToken();
  const auth = useAuthState();
  const [visible, setVisible] = useState(false);
  const isMultiWorkspacesMode = EnvironmentVariables.MULTI_WORKSPACES_MODE === "true";

  const { show: showIntercom } = useIntercom();

  const analytics = getAnalytics();
  // is anonymous memoized variable
  const isAnonymous = useMemo(
    () => auth.session.data?.user.isAnonymous,
    [auth.session.data?.user.isAnonymous]
  );
  const topMenuItems: Required<MenuProps>["items"] = useMemo(() => {
    // Feedback menu item
    const feedback: ItemType = {
      label: "Send Feedback",
      key: MenuKeys.FEEDBACK,
      icon: <FontAwesomeIcon icon={faThumbsUp} />,
    };

    // Feedback menu item
    const signOut: ItemType = {
      label: "Sign out",
      key: MenuKeys.SIGN_OUT,
      icon: <FontAwesomeIcon icon={faPowerOff} />,
    };

    // Feedback menu item
    const login: ItemType = {
      label: "Login",
      key: MenuKeys.LOGIN,
      icon: <FontAwesomeIcon icon={faPowerOff} />,
    };

    return [...(!isAnonymous && isMultiWorkspacesMode ? [feedback, signOut] : [feedback, login])];
  }, [isAnonymous, isMultiWorkspacesMode]);

  const showDrawer = () => {
    setVisible(true);
    analytics.track("workspace:menu:open");
  };

  const onClose = () => {
    setVisible(false);
    analytics.track("workspace:menu:close");
  };

  // Menu Select Handler
  const onMenuSelect = useCallback(
    (key: string) => {
      switch (key) {
        case MenuKeys.LOGIN:
          router.push(INDEX_ROUTE.getHref());
          break;
        case MenuKeys.FEEDBACK:
          analytics.track("workspace:menu:send-feedback");
          showIntercom();
          break;
        case MenuKeys.SIGN_OUT:
          analytics.track("workspace:menu:logout");
          signOut(getFirebaseAuth());
          break;
      }

      setVisible(false);
    },
    [showIntercom, setVisible, analytics]
  );

  return (
    <Flex
      justify="space-between"
      align="center"
      style={{ height: 70, paddingRight: token.padding }}
    >
      <Image
        style={{
          padding: token.paddingLG,
          paddingBottom: token.paddingXL,
        }}
        src={(mobilelogo as { src: string }).src}
        alt="Brand-mobile"
        preview={false}
        width={"60%"}
      />
      <Button
        type="primary"
        onClick={showDrawer}
        style={{
          padding: "20px 20px",
          fontSize: "18px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MenuOutlined />
      </Button>
      <Drawer
        title={
          <div
            style={{
              padding: 0,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              onClick={onClose}
              style={{
                padding: "20px 20px",
                fontSize: "18px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CloseOutlined />
            </Button>
          </div>
        }
        placement="top"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <Menu
          style={{ backgroundColor: "transparent" }}
          mode="inline"
          items={topMenuItems}
          onSelect={(info) => onMenuSelect(info.key)}
        />
      </Drawer>
    </Flex>
  );
}
