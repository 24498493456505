import { Spin } from "antd";
import { ErrorFeedback } from "components/shared/error-feedback";
import { useToken } from "components/ui-kit/core/token";
import { useAuthState } from "logic/auth/auth-state";
import { isTransportFailure } from "logic/internals/transports/transported-data/is-transport-failure";
import { TransportPhase } from "logic/internals/transports/transported-data/transport-phases";
import Head from "next/head";
import { ReactNode, useEffect, useState } from "react";

export function PageMobile(props: { title: string; desktop: ReactNode; mobile: ReactNode }) {
  const token = useToken();
  const auth = useAuthState();

  const initialScreenSize = typeof window === "undefined" ? undefined : window.innerWidth;

  const [screenSize, setScreenSize] = useState<number | undefined>(initialScreenSize);

  useEffect(() => {
    if (screenSize === undefined) {
      setScreenSize(window.innerWidth);
    }

    let timeout: number | undefined = undefined;

    const resizeListener = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = window.setTimeout(() => {
        setScreenSize(window.innerWidth);
      }, 300);
    };

    window.addEventListener("resize", resizeListener);

    const visibilityListener = () => {
      if (document.visibilityState === "visible") {
        setScreenSize(window.innerWidth);
      }
    };

    document.addEventListener("visibilitychange", visibilityListener);

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }

      window.removeEventListener("resize", resizeListener);
      document.removeEventListener("visibilitychange", visibilityListener);
    };
  }, [screenSize]);

  const isMobile = screenSize === undefined ? false : screenSize < 800;

  return (
    <>
      <Head>
        <title>{`${props.title} - Synthetic Users: user research without the headaches`}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      {auth.session.status === TransportPhase.Loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: token.marginMD,
            marginBottom: token.marginMD,
          }}
        >
          <Spin size="large" />
        </div>
      )}

      {auth.session.data && <>{isMobile ? props.mobile : props.desktop}</>}

      {isTransportFailure(auth.session.status) && <ErrorFeedback error={auth.session.status} />}
    </>
  );
}
