import { Button, Col, Empty, Pagination, Row, Space, Switch, Typography } from "antd";
import { ErrorFeedback } from "components/shared/error-feedback";
import { Spinner } from "components/ui-kit/components/spinner";
import { useToken } from "components/ui-kit/core/token";
import { isTransportFailure } from "logic/internals/transports/transported-data/is-transport-failure";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "store/hooks";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { useBookmarksFlag } from "../history.utils";
import HistoryCard from "./history-card";

type HistoryContentProps = {
  isMobile?: boolean;
};

export const HistoryContent: React.FC<HistoryContentProps> = ({ isMobile }) => {
  // Router hook
  const router = useRouter();
  // Theme token hook
  const token = useToken();
  // Redux state
  const history = useAppSelector((state) => state.history);
  const projectId = useAppSelector((state) => state.projects.data.project?.id);
  // Bookmarks flag
  const { isBookmarks, markBookmarksAsFalse, markBookmarksAsTrue } = useBookmarksFlag();

  // Bookmarked studies memoized variable
  const bookmarkedStudies = useMemo(
    () => history?.data?.filter((item) => item.bookmarked),
    [history?.data]
  );
  // has bookmarked studies memoized variable
  const hasBookmarkedStudies = useMemo(() => !!bookmarkedStudies?.length, [bookmarkedStudies]);

  // Local state
  const [showOnlyBookmarks, setShowOnlyBookmarks] = useState(isBookmarks);
  const [currentPage, setCurrentPage] = useState(1);

  // Current items memoized variable - all studies or only bookmarked studies
  const currentItems = useMemo(() => {
    if (showOnlyBookmarks) return bookmarkedStudies;
    return history?.data;
  }, [history?.data, showOnlyBookmarks, bookmarkedStudies]);

  // Pagination
  const pageSize = 6;
  const currentPageItems = useMemo(
    () => currentItems?.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    [currentItems, currentPage]
  );
  const totalItems = useMemo(() => currentItems?.length, [currentItems]);

  // Toggle bookmarked studies handler
  const handleBookmarkToggle = useCallback(() => {
    if (isMobile) {
      analytics.track("workspace:show-bookmarks", {
        is_showing: !showOnlyBookmarks,
        project_id: projectId || "",
      });
    } else {
      analytics.track("history:show-only-bookmarks-toggle", {
        is_showing: !showOnlyBookmarks,
        project_id: projectId ?? "",
      });
    }

    setShowOnlyBookmarks((prev) => !prev);
    showOnlyBookmarks ? markBookmarksAsFalse() : markBookmarksAsTrue();
  }, [showOnlyBookmarks, markBookmarksAsFalse, markBookmarksAsTrue, projectId, isMobile]);

  function handlePageChange(page: number) {
    if (isMobile) {
      analytics.track("workspace:select-page-number", {
        page,
        project_id: projectId || "",
      });
    } else {
      analytics.track("history:pagination:number", {
        page,
        project_id: projectId || "",
      });
    }
    setCurrentPage(page);
  }

  // Loading State
  if (history?.loading && !history?.data?.length) {
    return (
      <Spinner size="large" style={{ marginTop: token.padding, marginBottom: token.padding }} />
    );
  }

  // Error State
  if (isTransportFailure(history?.error)) {
    return (
      <ErrorFeedback
        error={history.error}
        style={{ marginTop: token.padding, marginBottom: token.padding }}
      />
    );
  }

  if (history.data && !history.loading && !history.data.length) {
    // Empty State
    return (
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Empty description="No studies found">
          <Button
            onClick={() => {
              analytics.track("history:create-new-study", {
                project_id: projectId,
              });
              router.push(INDEX_ROUTE.getHref());
            }}
          >
            Create new study
          </Button>
        </Empty>
      </Row>
    );
  }

  // Success State
  if (history?.data?.length) {
    return (
      <>
        <style jsx global>
          {`
            .ant-list-pagination {
              text-align: center !important;
            }
          `}
        </style>

        {/* Content */}
        <div style={{ padding: "32px" }}>
          {/* Top Pagination + Show only bookmarks toggle */}
          <Row justify={hasBookmarkedStudies ? "space-between" : "center"} align="bottom">
            {/* Show only bookmarks toggle - only display if the user has bookmarked studies */}
            {hasBookmarkedStudies && (
              <Space>
                <Typography.Text>Show only bookmarks</Typography.Text>
                <Switch checked={showOnlyBookmarks} onChange={handleBookmarkToggle} />
              </Space>
            )}
            {!isMobile && (
              <Pagination
                defaultCurrent={1}
                total={totalItems}
                pageSize={pageSize}
                showSizeChanger={false}
                current={currentPage}
                hideOnSinglePage
                onChange={(page) => {
                  {
                    handlePageChange(page);
                  }
                }}
              />
            )}
          </Row>

          {/* Studies list */}
          <Row gutter={[16, 16]} wrap style={{ marginBlock: "16px" }}>
            {currentPageItems?.map((study, i) => (
              <Col key={i} span={isMobile ? 24 : 8}>
                <HistoryCard {...study} isMobile={isMobile} />
              </Col>
            ))}
          </Row>

          {/* Bottom Pagination */}
          <Row justify="center">
            <Pagination
              defaultCurrent={1}
              total={totalItems}
              pageSize={pageSize}
              showSizeChanger={false}
              current={currentPage}
              hideOnSinglePage
              onChange={(page) => {
                handlePageChange(page);
              }}
            />
          </Row>
        </div>
      </>
    );
  }
};
