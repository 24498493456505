import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useToken } from "components/ui-kit/core/token";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getHistory } from "store/modules/history/actions";
import { getProjectById } from "store/modules/projects/actions";
import { HistoryContent } from "templates/history/content/history-container";
import { EmptyContainerMobile } from "./empty-container";

export function MainContent() {
  // Dispatch Hook
  const dispatch = useAppDispatch();
  // store state
  const history = useAppSelector((state) => state.history);
  const projectId = useAppSelector((state) => state.projects.data.project?.id);

  const projects = useAppSelector((state) => state.projects.data);
  const isMultiWorkspacesMode = EnvironmentVariables.MULTI_WORKSPACES_MODE === "true";

  const projectsList = useMemo(() => projects?.projectsList, [projects?.projectsList]);
  const project = useMemo(() => projects?.project, [projects?.project]);

  const token = useToken();

  const menu = (
    <Menu>
      {projectsList?.map((project) => (
        <Menu.Item
          key={project.id}
          onClick={() => {
            dispatch(getProjectById({ projectId: project.id }));
            analytics.track("workspace:menu:change-workspace", { project_id: project.id });
          }}
        >
          {project.description}
        </Menu.Item>
      ))}
    </Menu>
  );
  // API Call
  useEffect(() => {
    if (projectId) {
      dispatch(getHistory({ projectId: projectId }));
    }
  }, [projectId, dispatch]);

  return (
    <div style={{ padding: token.padding }}>
      <div hidden={!isMultiWorkspacesMode} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Dropdown overlay={menu}>
          <Button onClick={(e) => e.preventDefault()}>
            {project?.description} <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      {history?.data?.length ? <HistoryContent isMobile={true} /> : <EmptyContainerMobile />}
    </div>
  );
}
